import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    if ($(window).width() < 992) {
        $('.m-cats-carousel.-isCarousel').slick({
            dots: false,
            arrows: false,
            draggable: true,
            centerMode: false,
            centerPadding: 50,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            variableWidth: false,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 940,
                    settings: {
                        slidesToShow: 1.8,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.5,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    };
});