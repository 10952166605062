import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('#news-archive').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
        appendArrows: $('.m-news-carousel-arrows'),
        draggable: true,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 3.8,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('#news-submit').on('click', function(){
        var type = $('#news-type').find(':selected').val();
        var date = $('#news-date').find(':selected').val();

        $('#news-archive').slick('slickUnfilter');
    
        if(type === 'all' && date === 'all') {
            $('.archive-card').show();
        } else if(type === 'all' && date != 'all') {
            $('.archive-card').each(function() {
                var slideDate = $(this).data('date');
                var matchDate = (slideDate == date);
                var matchType = (type == 'all');

                if (matchDate && matchType) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });

            $('#news-archive').slick('slickFilter', '[data-date="' + date + '"]');

        } else if(type != 'all' && date === 'all') {
            $('.archive-card').each(function() {
                var slideDate = $(this).data('date');
                var matchDate = (date === "all");
                var matchType = ($(this).hasClass(type));

                if (matchDate && matchType) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
    
            $('#news-archive').slick('slickFilter', '.' + type);
            
        } else {
            $('.archive-card').each(function() {
                var slideDate = $(this).data('date');
                var matchDate = (date === "all" || slideDate == date);
                var matchType = (type == 'all' || $(this).hasClass(type));

                if (matchDate && matchType) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
    
            $('#news-archive').slick('slickFilter', '[data-date="' + date + '"]');
        };
    
        const $allSlider = $('#news-archive');
        const slick = $allSlider.slick('getSlick');
    
        if(slick.slideCount <= 0) {
            $('.m-news-carousel-none').show();
        } else {
            $('.m-news-carousel-none').hide();
        }
    });
});