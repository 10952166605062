import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).ready(function() {
    var $slider = $('.m-cards-list.-isCarousel');

    if ($slider.length) {
        var currentSlide;
        var slidesCount;
        var sliderCounter = document.createElement('span');
        sliderCounter.classList.add('m-cards-counter');
        
        var updateSliderCounter = function(slick, currentIndex) {
            currentSlide = slick.slickCurrentSlide() + 1;
            slidesCount = slick.slideCount;
            $(sliderCounter).text(currentSlide + ' of ' +slidesCount)
        };

        $slider.on('init', function(event, slick) {
            $('.m-cards-counter').append(sliderCounter);
            updateSliderCounter(slick);
        });

        $slider.on('afterChange', function(event, slick, currentSlide) {
            updateSliderCounter(slick, currentSlide);
        });

        $slider.slick({
            dots: false,
            arrows: true,
            prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5 me-md-0"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
            nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        appendArrows: $('.m-cards-arrows'),
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        appendArrows: $('.m-cards-arrows'),
                    }
                },
            ]
        });
    }
});