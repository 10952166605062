import 'jquery';
import { default as $ } from 'jquery';

$(window).on("load",function() {
    $(window).scroll(function() {
      var pageTop = $(document).scrollTop();
      var windowBottom = pageTop + $(window).height();

      $(".addAnimation").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.3;
        
        if (windowBottom > objectThreshold) {
          $this.addClass('m-fadeIn');
        };
      });

      $(".addSlideIn").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.8;
        
        if (windowBottom > objectThreshold) {
          $this.addClass('-slide');
        };
      });

      $(".addTitleSlide").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.5;
        
        if (windowBottom > objectThreshold) {
          $this.addClass('-slide');
        };
      });

      $(".m-media-cards-col").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.8;
        
        if (windowBottom > objectThreshold) {
          $this.addClass('-animate');
        };
      });

      $(".cardAnimation").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.2;
        
        if (windowBottom > objectThreshold) {
          $(this).find('.-card').addClass('cardFade');
        };
      });

      $(".m-cols-row.cardAnimation").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.1;
        
        if (windowBottom > objectThreshold) {
          $(this).find('.-card').addClass('cardFade');
        };
      });

      $(".m-journals-row.cardAnimation").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          $(this).find('.-card').addClass('cardFade');
        };
      });

      $(".postAnimation").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.2;
        
        if (windowBottom > objectThreshold) {
          $(this).find('.-card').addClass('cardFade');
        };
      });

      $(".borderAnimation").each(function() {
        var $this = $(this);
        var objectTop = $this.offset().top;
        var objectHeight = $this.outerHeight();
        var objectThreshold = objectTop + objectHeight * 0.2;
        
        if (windowBottom > objectThreshold) {
          $(this).removeClass('-animate');
        };
      });
    }).scroll();
  });
