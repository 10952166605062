import { default as $ } from 'jquery';

$(window).on("load",function() {
   $(".m-accordion-section").addClass('-noShow');
   $(".m-accordion-section:first").removeClass('-noShow');
   $(".m-accordion-content:first").slideDown();
   $(".m-accordion-plus:first").addClass('-active');
   
   $(".m-accordion-tab").on("click", function(){
      if ($(this).closest(".m-accordion-section").hasClass("-noShow")) {
         $(".m-accordion-content").slideUp();
         $(".m-accordion-plus").removeClass("-active");
         $(".m-accordion-section").addClass("-noShow");
         $(this).closest(".m-accordion-section").toggleClass("-noShow");
         $(this).closest(".m-accordion-section").find(".m-accordion-content").slideToggle();
         $(this).find(".m-accordion-plus").toggleClass('-active');
      } else {
         $(this).closest(".m-accordion-section").addClass("-noShow");
         $(".m-accordion-content").slideUp();
         $(".m-accordion-plus").removeClass("-active");
      };
   });

   $(".m-accordion-alt-section").addClass('-noShow');
   $(".m-accordion-alt-section:first").removeClass('-noShow');
   $(".m-accordion-alt-content:first").slideDown();
   $(".m-accordion-arrow:first").addClass('fa-chevron-up').removeClass('fa-chevron-down');
   
   $(".m-accordion-alt-tab").on("click", function(){
      $(this).closest(".m-accordion-section").toggleClass("-noShow");
      $(this).closest(".m-accordion-alt-section").find(".m-accordion-alt-content").slideToggle();
      $(this).find(".m-accordion-arrow").toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      // $(this).closest(".m-accordion-alt-section").find("iframe").prop("src", function(){
      //    return $(this).data("src");
      // });
   });
});

$(document).ready(function(){
   $('.m-accordion-video').each(function(i, obj) {
      $(this).find("iframe").prop("src", function(){
         return $(this).data("src");
      });
   });

   $('.m-accordion-video-alt').each(function(i, obj) {
      $(this).find("iframe").prop("src", function(){
         return $(this).data("src");
      });
   });
});