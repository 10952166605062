import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-carousel-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
        appendArrows: $('.m-related-arrows'),
        swipeToSlide: true,
        draggable: true,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.6,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.m-carousel-list-alt.-isCarousel').slick({
        dots: false,
        arrows: false,
        draggable: true,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.6,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});