import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    var results = false;

    $('#journal-year').on('change', function() {
        var date = $(this).val();

        if(date == 'all') {
            $('.m-journals-item').show();
        } else {
            $('.m-journals-item').each(function() {
                var journal = $(this).data('date');
                
                if(date == journal) {
                    $(this).show();
                    results = true;
                } else {
                    $(this).hide();
                }
            });
        };

        if(results == false) {
            $('.m-journals-noresult').show();
        } else {
            $('.m-journals-noresult').hide();
        }

        results = false;
    });
});