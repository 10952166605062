import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-books-carousel.-isCarousel').slick({
        dots: false,
        arrows: false,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                    variableWidth: true,
                    appendArrows: $('.m-books-arrows'),
                    arrows: true,
                }
            },
            {
                breakpoint: 578,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    centerMode: true,
                    appendArrows: $('.m-books-arrows'),
                    arrows: true,
                }
            },
        ]
    });

    $('.m-books-carousel-archive.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
        appendArrows: $('.m-books-arrows'),
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                    variableWidth: true,
                }
            },
            {
                breakpoint: 578,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    centerMode: true,
                }
            },
        ]
    });
});