import { default as $ } from 'jquery';

$(window).on("load",function() {
    // FAQ block accordion
    $(".m-faq-tab").on("click", function(){
        $(this).find('.m-faq-plus').toggleClass('-active');
        $(this).find('.m-faq-name').toggleClass('-active');
        $(this).closest('.m-faq-row').find('.m-faq-content').slideToggle();
    });

    // Faq alt block accordion
    $('.m-faq-alt-content:first').addClass('-animate');
    $('.m-faq-alt-plus:first').addClass('-active');
    $('.m-faq-alt-name:first').addClass('-active');
    $('.m-faq-alt-tab:first').addClass('-show');

    $(".m-faq-alt-tab").on("click", function(){
        $('.m-faq-alt-plus').removeClass('-active');
        $(this).find('.m-faq-alt-plus').addClass('-active');
        $('.m-faq-alt-name').removeClass('-active');
        $(this).find('.m-faq-alt-name').addClass('-active');

        $('.m-faq-alt-tab').removeClass('-show');
        $(this).addClass('-show');

        $('.m-faq-alt-content').removeClass('-animate');

        var tab = $(this).data('tab');
        $('[data-content="'+tab+'"]').addClass('-animate');
    });
});