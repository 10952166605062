import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    var header = $("#header");
    var hamburgerButton = $("#dropdownButton");
    var hamburgerBars = $(".header-hamburger-bars");
    var dropdownMenu = $("#dropdownMenu");

    $(window).on('scroll', function() {
        var scroll = $(window).scrollTop();

        if ($(window).width() < 768) {
            if (scroll >= 80) {
                header.addClass("-bg");
                hamburgerButton.addClass("-bg");
                hamburgerBars.addClass("-bg");
            } else {
                header.removeClass("-bg");
                hamburgerButton.removeClass("-bg");
                hamburgerBars.removeClass("-bg");
            }
        } else {
            if (scroll >= 120) {
                header.addClass("-bg");
                hamburgerButton.addClass("-bg");
                hamburgerBars.addClass("-bg");
            } else {
                header.removeClass("-bg");
                hamburgerButton.removeClass("-bg");
                hamburgerBars.removeClass("-bg");
            }
        };
    });

    var colour = $('.m-banner').data('header');
    
    if(colour == 'light') {
        header.addClass('header-light').removeClass('header-dark');
        hamburgerButton.addClass('-light').removeClass('-dark');
        hamburgerBars.addClass('-light').removeClass('-dark');
    };
    
    hamburgerButton.on('click', function(){
        $('#body').toggleClass('-no-scroll');
        $('#nav').toggleClass('-bg');
        $('#header').toggleClass('-dropdown');
        $('.header-hamburger-bars').toggleClass('-active');
        $('.header-hamburger-icon').toggle();
        dropdownMenu.toggleClass('-active').toggleClass('-hidden');
    });
});