import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-people-carousel.-isCarousel').each(function(i, obj) {
        var dots = $(this).attr('id');

        $(this).slick({
            dots: false,
            arrows: true,
            prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
            nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
            appendArrows: $('.m-people-arrows.'+dots),
            draggable: true,
            swipeToSlide: true,
            centerMode: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 3.8,
                    }
                },
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 3.6,
                    }
                },
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 3.4,
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3.2,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2.8,
                    }
                },
                {
                    breakpoint: 1150,
                    settings: {
                        slidesToShow: 2.6,
                    }
                },
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 2.4,
                    }
                },
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 750,
                    settings: {
                        slidesToShow: 1.8,
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 1.6,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1.4,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1.2,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    });
});