import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-issues-carousel.-isCarousel').each(function(i, obj) {
        var dots = $(this).attr('id');

        $(this).slick({
            dots: false,
            arrows: true,
            prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-chevron-left c-navy"></i></button>',
            nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-chevron-right c-navy"></i></button>',
            appendArrows: $('.m-issues-arrows.'+dots),
            draggable: true,
            swipeToSlide: true,
            centerMode: false,
            slidesToShow: 2.4,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 1.9,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 1.7,
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 1.8,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 1.7,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 1.5,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1.2,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.4,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1.2,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    });
});