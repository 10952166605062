// Vendor js libraries
import '@popperjs/core';
import 'bootstrap';
import Splitting from "splitting";
import ScrollOut from "scroll-out";
import "mmenu-js";
import '@accessible360/accessible-slick';
import 'jquery';

// Parachute js files
import './parachute/parachute';