import { default as $ } from 'jquery';

$(window).on("load",function() {
   // Media text cols block - section accordion
   $('.m-cols-accordion').each(function(i, obj) {
      $(this).find('.m-cols-accordion-section:first').addClass('-show');
      $(this).find('.m-cols-accordion-section:first').find('.m-cols-accordion-content').slideDown();
      $(this).find('.m-cols-accordion-section:first').find('.m-cols-plus').addClass('-active'); 
   });

   $(".m-cols-accordion-tab").on("click", function(){
      if($(this).closest('.m-cols-accordion-section').hasClass('-show')) {
         $(this).closest('.m-cols-accordion').find('.m-cols-plus').removeClass('-active');
         $(this).closest('.m-cols-accordion').find('.m-cols-accordion-section').removeClass('-show');
         $(this).closest('.m-cols-accordion').find('.m-cols-accordion-content').slideUp();
      } else {
         $(this).closest('.m-cols-accordion').find('.m-cols-plus').removeClass('-active');
         $(this).find('.m-cols-plus').addClass('-active');
         $(this).closest('.m-cols-accordion').find('.m-cols-accordion-section').removeClass('-show');
         $(this).closest('.m-cols-accordion-section').addClass('-show');
         $(this).closest('.m-cols-accordion').find('.m-cols-accordion-content').slideUp();
         $(this).closest('.m-cols-accordion-section').find('.m-cols-accordion-content').slideDown();
      }
   });

});